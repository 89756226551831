import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Redirect } from "react-router-dom";

class Auth extends Component {
  render() {
    if (this.props.apiKey === "-1") {
      // Now Loading ...
      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="12">
                <p className="text-center">Now Loading...</p>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (this.props.apiKey === "") {
      // Login
      return <Redirect to={"/login"} />;
    } else {
      return this.props.children;
    }
  }
}

export default Auth;
